import React from 'react';
import { Heading } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
const { Money } = sdkTypes;

import css from './ListingPage.module.css';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';

const SectionPackages = props => {
  const { publicData, intl, marketplaceCurrency } = props;

  if (!publicData.packages)
    return null;

  return (
    <section className={css.sectionPackages}>
      <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
        <FormattedMessage id="ListingPage.packagesTitle" />
      </Heading>
      <div>
        {publicData.packages.map((item) => {
          const formattedPrice = formatMoney(intl, new Money(item.price, marketplaceCurrency));

          return (
            <div className={css.packageItem} key={item.title}>
              <div className={css.packageTitleHolder}>
                <h4 className={css.packageTitle}>{item.title}</h4>
                <span className={css.packagePrice}>{formattedPrice}</span>
              </div>
              <div>{item.description}</div>
            </div>
          )
        })}
      </div>
    </section>
  );
};

export default SectionPackages;
